/* eslint-disable  */

export default {
  "About us": "Chi siamo",
  "Account": "Account",
  "Add new address": "Aggiungi un nuovo indirizzo",
  "Add to compare": "Aggiungi per confrontare",
  "Add to Wishlist": "Aggiungi alla Wishlist",
  "Additional Information": "Spedizioni e Resi",
  "All Orders": "Tutti gli ordini",
  "Allow order notifications": "Consenti le notifiche degli ordini",
  "Apply": "Applica",
  "Applied Coupon": "Buono Applicato",
  "Attention!": "Attenzione!",
  "Back to home": "Torna alla home",
  "Back to homepage": "Torna alla homepage",
  "Billing": "Fatturazione",
  "Billing address": "Indirizzo Di Fatturazione",
  "Brand": "Brand",
  "Brand_url": "/it/brand",
  "Cancel": "Cancella",
  "Cart": "Carrello",
  "Categories": "Categorie",
  "Change": "Cambia",
  "Change password your account": "Se desideri modificare la password per accedere al tuo account, inserisci le seguenti informazioni",
  "Children": "Bambino",
  "Children_url": "/it/home/bambino",
  "Clear all": "Cancella tutto",
  "Color": "Colore",
  "Commercial information": "ed accetto di ricevere informazioni commerciali personalizzate da Needstore via e-mail",
  "Contact details updated": "Mantieni aggiornati i tuoi indirizzi e recapiti.",
  "Contact us": "Contattaci",
  "Continue to billing": "Continua con la fatturazione",
  "Continue to payment": "Procedi al pagamento",
  "Continue to shipping": "Continua con la spedizione",
  "Cookies Policy": "Cookies Policy",
  "Create an account": "Creare un account",
  "Customer Reviews": "Recensioni dei clienti",
  "Customer service": "Assistenza clienti",
  "Date": "Data",
  "Default Billing Address": "Indirizzo di fatturazione",
  "Default Shipping Address": "Indirizzo di spedizione predefinito",
  "Delete": "Elimina",
  "Departments": "Dipartimenti",
  "Description": "Descrizione",
  "Details and status orders": "Controlla i dettagli e lo stato dei tuoi ordini nel negozio online. Puoi anche annullare l'ordine o richiedere un reso.",
  "Discount": "Sconto",
  "Done": "Fatto",
  "Download": "Scarica",
  "Download all": "Scarica tutto",
  "Edit": "Modifica",
  "Email address": "Indirizzo Email",
  "Empty": "Sembra che tu non abbia ancora aggiunto alcun articolo al carrello. Inizia a fare acquisti.",
  "Empty bag": "Carrello vuoto",
  "Enjoy your free account": "Goditi questi vantaggi con il tuo account gratuito!",
  "Enter promo code": "Inserisci il codice promozionale",
  "Feedback": "Il tuo feedback è importante per noi. Facci sapere cosa potremmo migliorare.",
  "Feel free to edit": "Sentiti libero di modificare i tuoi dati qui sotto in modo che il tuo account sia sempre aggiornato",
  "Filters": "Filtri",
  "Find out more": "Scopri di più",
  "First Name": "Nome",
  "Forgot Password": "Se non ricordi la password, puoi reimpostarla.",
  "Forgot Password Modal Email": "Email che stai utilizzando per accedere:",
  "forgotPasswordConfirmation": "Grazie! Se c'è un account registrato con l'email {0}, troverai un messaggio con un link per reimpostare la password nella tua casella di posta.",
  "Forgotten password?": "Password dimenticata?",
  "Go back": "Torna indietro",
  "Go back shopping": "Torna a fare acquisti",
  "Go back to shop": "Torna al negozio",
  "Go to checkout": "Vai alla cassa",
  "Go to cart": "Vai al carrello",
  "Guarantee": "Garanzia",
  "Help": "Aiuto",
  "Help & FAQs": "Aiuto & FAQs",
  "hide": "nascondi",
  "Home": "Home",
  "I agree to": "Sono d'accordo",
  "I confirm subscription": "Confermo l'iscrizione",
  "I want to create an account": "Voglio creare un account",
  "Info after order": "Puoi accedere al tuo account utilizzando l'e-mail e la password definite in precedenza. Sul tuo account puoi modificare i dati del tuo profilo, controllare la cronologia delle transazioni, modificare l'iscrizione alla newsletter.",
  "Instruction1": "Take care of me",
  "Instruction2": "Just here for the care instructions?",
  "Instruction3": "Yeah, we thought so",
  "It was not possible to request a new password, please check the entered email address.": "Non è stato possibile richiedere una nuova password, controlla l'indirizzo email inserito.",
  "Item": "Articolo",
  "Items": "Articoli",
  "Kidswear": "Kidswear",
  "Last Name": "Cognome",
  "leggi la politica di resi": "leggi la politica di resi",
  "Let’s start now – we’ll help you": "Cominciamo ora: ti aiuteremo.",
  "Log into your account": "Accedi al tuo account",
  "Login": "Login",
  "login in to your account": "accedi al tuo account",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Sembra che tu non abbia ancora aggiunto alcun articolo al carrello. Inizia a fare acquisti.",
  "Looks like you haven’t added any items to the Wishlist.": "Sembra che tu non abbia aggiunto alcun articolo alla lista dei desideri.",
  "Make an order": "Effettuare un ordine",
  "Manage addresses": "Gestisci tutti gli indirizzi che desideri (luogo di lavoro, indirizzo di casa...) In questo modo non dovrai inserire l'indirizzo manualmente ad ogni ordine.",
  "Manage billing addresses": "Gestisci tutti gli indirizzi di fatturazione che desideri (luogo di lavoro, indirizzo di casa...) In questo modo non dovrai inserire manualmente l'indirizzo di fatturazione ad ogni ordine.",
  "Manage shipping addresses": "Gestisci tutti gli indirizzi di spedizione che desideri (luogo di lavoro, indirizzo di casa...) In questo modo non dovrai inserire manualmente l'indirizzo di spedizione ad ogni ordine.",
  "Match it with": "Abbinalo a",
  "Men": "Uomo",
  "Men_url": "/it/home/uomo",
  "Men fashion": "Men fashion",
  "Menu": "Menu",
  "My billing and shipping address are the same": "Il mio indirizzo di fatturazione e quello di spedizione sono gli stessi",
  "My Cart": "Il mio carrello",
  "No account": "Non hai ancora un'account?",
  "or": "o",
  "or fill the details below": "o compilare i dettagli di seguito",
  "Order ID": "ID ordine",
  "Order information": "Informazioni sull'ordine",
  "Order No.": "Ordine No.",
  "Order summary": "Riepilogo ordine",
  "Orders": "Ordini",
  "Other products you might like": "Altri prodotti che potrebbero piacerti",
  "Out of stock": "Esaurito",
  "Password": "Password",
  "Password Changed": "Password modificata con successo. Ora puoi tornare alla home page e accedere.",
  "Pay for order": "Paga l'ordine",
  "Payment": "Pagamento",
  "Payment & Delivery": "Pagamento e consegna",
  "Payment Method": "Metodo di pagamento",
  "Payment Methods": "Modalità di pagamento",
  "Personal details": "Dati personali",
  "Please type your current password to change your email address.": "Digita la tua password attuale per modificare il tuo indirizzo email.",
  "Price": "Prezzo",
  "Privacy": "Privacy",
  "Privacy Policy": "Privacy Policy",
  "Product": "Prodotto",
  "Product suggestions": "Suggerimenti sui prodotti",
  "Products": "Prodotti",
  "Products found": "Prodotti trovati",
  "Purchase terms": "Condizioni di acquisto",
  "Quality in the details": "Qualità nei dettagli",
  "Quantity": "Quantità",
  "Read all reviews": "Leggi le recensioni",
  "Read and understand": "Ho letto e compreso il",
  "Read reviews": "Recensioni",
  "Register": "Registrati",
  "Register today": "Registrati ora",
  "Remove": "Rimuovi",
  "Remove Address": "Rimuovi indirizzo",
  "Remove from Wishlist": "Rimuovi dalla wishlist",
  "Repeat Password": "Ripeti la password",
  "Reset Password": "Reset Password",
  "Resi_url": "/it/resi",
  "Review my order": "Controlla il mio ordine",
  "Same as shipping address": "Uguale all'indirizzo di spedizione",
  "Save changes": "Salvare le modifiche",
  "Save for later": "Salva per dopo",
  "Save Password": "Salva la password",
  "Search": "Ricerca",
  "Search for items": "Cerca",
  "Search results": "Risultati di ricerca",
  "Sections that interest you": "Sezioni che ti interessano",
  "See all results": "Risultati per",
  "See more": "Vedi altro",
  "Select payment method": "Scegli il metodo di pagamento",
  "Select shipping method": "Seleziona il metodo di spedizione",
  "Send my feedback": "Invia il mio feedback",
  "Set up newsletter": "Imposta la tua newsletter e ti invieremo ogni settimana informazioni su nuovi prodotti e tendenze dalle sezioni che hai selezionato.",
  "Share your look": "Condividi il tuo look",
  "Shipping": "Spedizione",
  "Shipping address": "Indirizzo di spedizione",
  "Shipping details": "Dettagli di spedizione",
  "Shipping method": "Metodo di spedizione",
  "Show":"Mostrare",
  "show more": "mostra di più",
  "Show on page": "Mostra a pagina",
  "Sign in": "Registrazione",
  "Size guide": "Guida alle taglie",
  "Sign Up for Newsletter": "Iscriviti alla Newsletter",
  "Sort by": "Ordina per",
  "Sort: Default": "Predefinito",
  "Sort: Name A-Z": "Nome A-Z",
  "Sort: Name Z-A": "Nome Z-A",
  "Sort: Price from high to low": "Prezzo da alto a basso",
  "Sort: Price from low to high": "Prezzo dal basso verso l'alto",
  "Start shopping": "Inizia a fare acquisti",
  "Status": "Stato",
  "Subscribe": "Sottoscrivi",
  "Subscribe to newsletter": "Iscriviti alla Newsletter",
  "subscribeToNewsletterModalContent": "Dopo esserti iscritto alla newsletter, riceverai offerte speciali e messaggi da VSF via e-mail. Non venderemo o distribuiremo la tua e-mail a terzi in nessun momento. Si prega di consultare il nostro {0}.",
  "Subtotal": "Totale parziale",
  "Subtotal price": "Prezzo subtotale",
  "Successful placed order": "Hai effettuato con successo l'ordine. Riceverai un'e-mail di conferma dell'ordine con i dettagli del tuo ordine e un link per seguirne lo stato di avanzamento.",
  "Terms and conditions": "Terms and conditions",
  "Thank you": "Grazie",
  "Thank You Inbox": "Se il messaggio non arriva nella tua casella di posta, prova un altro indirizzo email che potresti aver usato per registrarti.",
  "Total": "Totale",
  "Order Total": "Ordine totale",
  "Total items": "Articoli totali",
  "Total price": "Prezzo totale",
  "Update password": "Aggiorna password",
  "Update personal data": "Aggiorna dati",
  "Use your personal data": "Noi di Brand name attribuiamo grande importanza alle questioni relative alla privacy e ci impegniamo a proteggere i dati personali dei nostri utenti. Scopri di più su come ci prendiamo cura e utilizziamo i tuoi dati personali nel",
  "User Account": "Account utente",
  "View": "Visualizzazione",
  "View details": "Visualizza dettagli",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Chi siamo",
  "Wishlist": "Wishlist",
  "Women": "Donna",
  "Women_url": "/it/home/donna",
  "Women fashion": "Women fashion",
  "You added {product} to your shopping cart.": "Hai aggiunto {product} al tuo carrello.",
  "You are not authorized, please log in": "Non sei autorizzato, effettua il login",
  "You can unsubscribe at any time": "È possibile disdire in qualsiasi momento",
  "You currently have no orders": "Al momento non hai ordini",
  "You haven\’t searched for items yet": "Non hai ancora cercato elementi.",
  "Your bag is empty": "La tua wishlist è vuota",
  "Your current email address is": "Il tuo attuale indirizzo email è",
  "Your email": "La tua email",
  "The email field must be a valid email":"Il campo email deve essere un indirizzo email valido",
  'You have submitted no reviews': 'Non hai inviato recensioni',
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"L'accesso all'account non è corretto o il tuo account è temporaneamente disattivato. Attendere e riprovare più tardi.",
  "A customer with the same email address already exists in an associated website.": "Un cliente con lo stesso indirizzo email esiste già in un sito web associato.",
  "Invalid email": "Invalid email",
  "SUMMER COLLECTION {year}": "COLLEZIONE ESTIVA {year}",
  "Colorful summer dresses are already in store": "I vestiti estivi colorati sono già in negozio",
  "Learn more": "Per saperne di più",
  "Dresses": "Vestiti",
  "Cocktail & Party": "Cocktail party",
  "Linen Dresses": "Abiti di lino",
  "T-Shirts": "T-Shirts",
  "The office life": "La vita d'ufficio",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Trova splendidi abiti da cocktail e abiti da festa da donna. Distinguiti con abiti da cocktail in pizzo e metallizzati di tutti i tuoi brand preferiti.",
  "Shop now": "Acquistare ora",
  "The Office Life": "La vita in ufficio",
  "Summer Sandals": "Sandali estivi",
  "Eco Sandals": "Sandali ecologici",
  "Subscribe to Newsletters": "Iscriviti alle newsletter",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Siate consapevoli delle prossime vendite ed eventi. Ricevi regali e offerte speciali",
  "Fashion to take away": "Moda da portare via",
  "Download our application to your mobile": "Scarica la nostra applicazione sul tuo cellulare",
  "Share Your Look": "Condividi il tuo look",
  "My Account": "Il mio account",
  "My profile": "Il mio profilo",
  "Personal Details": "Dati personali",
  "Addresses details": "Dettagli indirizzi",
  "My newsletter": "La mia newsletter",
  "Log out": "Log out",
  "My reviews": "Le mie recensioni",
  "Order history": "Cronologia ordini",
  "Order details": "Dettagli dell'ordine",
  "My wishlist": "La mia lista dei desideri",
  "Password change": "Modifica della password",
  "Personal data": "Dati personali",
  "Your e-mail": "La tua email",
  "Current Password": "Password attuale",
  "You are not authorized, please log in.": "Non sei autorizzato, effettua il login.",
  "Go To Product": "Vai al prodotto",
  "Change to list view": "Passa alla visualizzazione elenco",
  "Change to grid view": "Passa alla vista griglia",
  "Returns":"Resi",
  "My orders": "I miei ordini",
  "Add the address": "Aggiungi l'indirizzo",
  "Set as default shipping": "Imposta come spedizione predefinita",
  "Set as default billing": "Imposta come fatturazione predefinita",
  "House/Apartment number": "Numero civico/appartamento",
  "Street Name": "Nome della via",
  "City": "Città",
  "State/Province": "Stato/Provincia",
  "Zip-code": "Cap",
  "Country": "Paese",
  "Phone number": "Numero di telefono",
  "Please select a country first":"Seleziona prima un paese",
  "This field is required": "Questo campo è obbligatorio",
  "The field should have at least 2 characters": "Il campo deve contenere almeno 2 caratteri",
  "The field should have at least 4 characters": "Il campo deve contenere almeno 4 caratteri",
  "The field should have at least 8 characters": "Il campo deve contenere almeno 8 caratteri",
  "New Password": "Nuova password",
  "New Products": "Nuovi Prodotti",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "Non ci sono metodi di spedizione disponibili per questo paese. Siamo spiacenti, prova con un paese diverso.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "Si è verificato un errore durante il tentativo di recuperare i metodi di spedizione. Siamo spiacenti, prova con dettagli di spedizione diversi.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "Si è verificato un errore durante il tentativo di selezionare questo metodo di spedizione. Siamo spiacenti, prova con un metodo di spedizione diverso.",
  "We can't find products matching the selection.":"Non riusciamo a trovare prodotti corrispondenti alla selezione.",
  'Page not found': 'Pagina non trovata',
  'Back to Home page': 'Torna alla pagina principale',
  'An error occurred': 'Server in manutenzione',
  "AllProductsFromCategory": "Tutte le {categoryName}",
  "Show more": "Mostra di più",
  "Show less": "Mostra meno",
  "Yes": "Si",
  "No": "No",
  "Apply filters": "Applicare filtri",
  "The coupon code isn't valid. Verify the code and try again.": "Il codice coupon non è valido. Verifica il codice e riprova.",
  "From": "A partire dal",
  "To": "Al",
  "Your customization": "La tua personalizzazione",
  "Passwords don't match":"Le password non corrispondono",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":"La password deve essere lunga almeno 8 caratteri e deve contenere almeno: 1 lettera maiuscola o minuscola, 1 numero o un carattere speciale (Es. , . _ & ? ecc.)",
  "Show all products":"Mostra tutti i prodotti",
  "Select previously saved address":"Seleziona l'indirizzo precedentemente salvato",
  "Enter different address":"Inserisci un indirizzo diverso",
  "You must confirm your account. Please check your email for the confirmation link.": "Registrazione avvenuta con successo. Effettuare il login.",
  "Change Store":"Cambia negozio",
  "Choose Currency":"Scegli Valuta",
  "Add a review":"Aggiungi una recensione",
  "Add to cart":"Aggiungi al carrello",
  "Title":"Titolo",
  "Name":"Nome",
  "Review":"Revisione",
  "Add review":"Aggiungi recensione",
  "Are you sure you would like to remove this item from the shopping cart?":"Sei sicuro di voler rimuovere questo articolo dal carrello?",
  "Your cart is empty":"il tuo carrello è vuoto",
  "Are you sure?":"Sei sicuro?",
  "{0} has been successfully removed from your cart":"{0} è stato rimosso con successo dal tuo carrello",
  "Amount":"Quantità",
  "Thank you for your order!":"Grazie per il tuo ordine!",
  "Your Purchase":"Il tuo acquisto",
  "Primary contacts for any questions":"Serve aiuto?",
  "Your Account":"Il tuo account",
  "What can we improve":"Cosa possiamo migliorare",
  "Payment date":"Data di pagamento",
  "The user password was changed successfully updated!":"La password utente è stata modificata correttamente aggiornata!",
  "The user account data was successfully updated!":"I dati dell'account utente sono stati aggiornati con successo!",
  "You submitted your review for moderation.": "Hai inviato la tua recensione per la moderazione.",
  "Starting at": "A partire da",
  "Disponibilità": "Disponibilità",
  "IN_STOCK": "Disponibile",
  "OUT_OF_STOCK": "Non disponibile",
  "gratuita per ordini superiori ad 89€": "gratuita per ordini superiori ad 89€",
  "Reso": "Reso",
  "fino a 30gg": "fino a 30gg",
  "Servizio Clienti": "Servizio Clienti",
  "Spedizione Gratis": "Spedizione Gratis",
  "Spedizione Gratis per ordini superiori ad 89€ in Italia. Per ordini inferiori la spedizione è 5.99€": "Spedizione Gratis per ordini superiori ad 89€ in Italia. Per ordini inferiori la spedizione è 5.99€",
  "Siamo sempre a tua disposizione su": "Siamo sempre a tua disposizione su",
  "Puoi sostituire il prodotto o la taglia acquistata entro 30gg gratuitamente con un altro articolo o una taglia diversa.": "Puoi sostituire il prodotto o la taglia acquistata entro 30gg gratuitamente con un altro articolo o una taglia diversa.",
  "Pagamenti Sicuri 100%": "Pagamenti Sicuri 100%",
  "Hai a disposizione tanti metodi di pagamento: Carta di Credito / Bancomat, Paypal, Scalapay, Bonifico e Contrassegno.": "Hai a disposizione tanti metodi di pagamento: Carta di Credito / Bancomat, Paypal, Scalapay, Bonifico e Contrassegno.",
  "gratuita": "gratuita",
  "per ordini superiori ad €89 | Compra Ora, paga Poi con": "per ordini superiori ad €89 | Compra Ora, paga Poi con",
  "Spedizione gratuita per ordini superiori ad €89": 'Spedizione gratuita per ordini superiori ad €89.',
  "Compra Ora, paga Poi con Scalapay in 3 rate": 'Compra Ora, paga Poi con Scalapay in 3 rate.',
  "Select an available size!": "Seleziona una taglia disponibile!",
  "Update the address": "Aggiorna l'indirizzo",
  '30 giorni per il cambio': '30 giorni per il cambio',
  'Il prezzo più basso degli ultimi 30 giorni': 'Il prezzo più basso degli ultimi 30 giorni',
  'The requested qty is not available': 'La quantità richiesta non è disponibile',
  'Pagamenti sicuri': 'Pagamenti sicuri',
  'con certificato SSL': 'con certificato SSL',
  'Non sai che taglia prendere?': 'Non sai che taglia prendere?',
  'Lasciati consigliare da un nostro esperto su': 'Lasciati consigliare da un nostro esperto su',
  'Apri la tabella delle Scarpe': 'Apri la tabella delle Scarpe',
  'Apri la tabella delle Cinture': 'Apri la tabella delle Cinture',
};
